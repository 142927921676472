





































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ResidenTable",
  props: {
    residenSet: Array,
  },
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
  },
});
